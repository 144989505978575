import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  SuppressNavigableCallbackParams
} from '@ag-grid-community/core'
import cx from 'classnames'
import dayjs from 'dayjs'

import { formatCoupon, formatMaturityFullDate } from '../../helpers/formatting'
import {
  OrderFields,
  OrderManager
} from '../../helpers/hooks/useManageMyOrders'
import {
  withOrderField,
  withOrderManager
} from '../../helpers/WithOrderManagerHOCs'
import { OrderType } from '../../store/order/types'
import { Security } from '../../store/securities/reducer'
import styles from './bondListStyle.module.scss'

import BestPriceCell from './cells/BestPriceCell'
import BestSizeCell from './cells/BestSizeCell'
import BestSpreadCell from './cells/BestSpreadCell'
import BestYTWCell from './cells/BestYTWCell'
import DepthOfMarketControls from './cells/DepthOfMarketControls'
import HitLiftCell from './cells/HitLiftCell'
import MarketVolumeCell from './cells/MarketVolumeCell'
import MyOrderEditor from './cells/MyOrderEditor'
import { MyOrderRenderer } from './cells/MyOrderRenderer'
import OrderCheckboxCell from './cells/OrderCheckboxCell'
import SecurityCheckboxCell from './cells/SecurityCheckboxCell'
import SubmitOrCancelCell from './cells/SubmitOrCancelCell'
import { getHeaderOrderCheckbox } from './HeaderOrderCheckbox'
import HeaderSecurityCheckbox from './HeaderSecurityCheckbox'
import ChordCells from './cells/ChordCells'

export const CHECKBOX = 'CHECKBOX'
export const ISSUER = 'issuerSymbol'
export const COUPON = 'COUPON'
export const MATURITY = 'MATURITY'
export const BEST_BID_HIT = 'bestBidHit'
export const BEST_BID_SIZE = 'bestBidSize'
export const BEST_BID_PRICE = 'bestBidPrice'
export const BEST_BID_SPREAD = 'bestBidSpread'
export const BEST_BID_YTW = 'bestBidYTW'
export const BEST_OFFER_LIFT = 'bestOfferLift'
export const MARKET_VOLUME = 'marketVolume'
export const INTERNAL_VOLUME = 'internalVolume'
export const BEST_OFFER_PRICE = 'bestOfferPrice'
export const BEST_OFFER_SIZE = 'bestOfferSize'
export const BEST_OFFER_SPREAD = 'bestOfferSpread'
export const BEST_OFFER_YTW = 'bestOfferYTW'
export const MY_BID_SELECT = 'myBidSelect'
export const MY_BID_SUBMIT_OR_CANCEL = 'myBidSubmitOrCancel'
export const MY_BID_PRICE = 'myBidPrice'
export const MY_BID_SPREAD = 'myBidSpread'
export const MY_BID_SIZE = 'myBidSize'
export const MY_OFFER_SPREAD = 'myOfferSpread'
export const MY_OFFER_PRICE = 'myOfferPrice'
export const MY_OFFER_SIZE = 'myOfferSize'
export const MY_OFFER_SELECT = 'myOfferSelect'
export const MY_OFFER_SUBMIT_OR_CANCEL = 'myOfferSubmitOrCancel'
export const BEST_BID = 'bestBid'
export const MY_BID = 'myBid'
export const BEST_OFFER = 'bestOffer'
export const MY_OFFER = 'myOffer'
export const MY_BID_EDIT = 'myBidEdit'
export const MY_OFFER_EDIT = 'myOfferEdit'

export const CURRENCY = 'currency'
export const CUSIP = 'cusip'
export const ISIN = 'isin'
export const BENCHMARK_NAME = 'benchmarkName'
export const AMOUNT_ISSUED = 'amountIssued'
export const AMOUNT_OUTSTANDING = 'amountOutstanding'
export const BENCHMARK_ISIN = 'benchmarkISIN'
export const BENCHMARK_SECURITY = 'benchmarkSecurity'
export const COUNTRY = 'country'
export const ISSUE_DATE = 'issueDate'
export const MARKUP_RATE = 'markupRate'
export const SETTLEMENT_DATE = 'settlementDate'
export const S_AND_P_RATING = 'sAndPRating'
export const SECTOR = 'sector'
export const SERIES = 'series'
export const ISSUER_NAME = 'issuerName'

// 7CHORD ADMIN COLUMNS
export const AVG_DAILY_7CHORD = 'averageDailyTradeCount_7Chord'
export const TIMES_TRADED_7CHORD = 'timesTraded_7Chord'
export const TIME_SINCE_7CHORD = 'timeSinceLastTrade_7Chord'
export const EXPERT_PREDICTION_7CHORD = 'topExpertPrediction_7Chord'

export const PRICE_7CHORD = 'price_7Chord'
export const DATETIME_7CHORD = 'dateTime_7Chord'
export const OAS_SPREAD_7CHORD = 'oasSpread_7Chord'
export const T_SPREAD = 'tSpread_7Chord'
export const YTM_7CHORD = 'ytm_7Chord'
export const DAY_7CHORD = 'dayOverSignal_7Chord'
export const SIGNAL_7CHORD = 'signalOverSignal_7Chord'
export const LAST_CLOSE_7CHORD = 'lastClose_7Chord'

const orderTooltipProps = (orderType: OrderType) => ({
  tooltipField: 'id',
  tooltipComponent: 'orderTooltip',
  tooltipComponentParams: { orderType }
})

/* const stackSizeTooltipProps = (orderType: OrderType, gridIndex: number) => ({
  tooltipField: 'id',
  tooltipComponent: 'stackSizeTooltip',
  tooltipComponentParams: { orderType, gridIndex }
})*/

// always shows menu (eMenu) for accessibility, uses title for tooltip
export const showMenuPartialColumn = {
  headerComponentParams: {
    template:
      '<div class="ag-cell-label-container" role="presentation">' +
      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"  title="Edit Columns"></span>' +
      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
      '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
      '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
      '  </div>' +
      '</div>'
  }
}

const shouldDisplayIssuer = ({ api, data, rowIndex }: CellClassParams) => {
  if (!data) return true
  const previousRow = api.getDisplayedRowAtIndex(rowIndex - 1)
  return previousRow?.data?.issuerSymbol !== data.issuerSymbol
}

type AddHide<T> = T & { hide?: boolean }

export const pinnedLeftColumns = (
  gridIndex: number
): Array<AddHide<ColDef>> => [
  {
    colId: CHECKBOX,
    suppressMovable: true,
    suppressAutoSize: true,
    pinned: 'left',
    width: 30,
    suppressColumnsToolPanel: true,
    headerComponent: HeaderSecurityCheckbox(gridIndex),
    headerTooltip: 'Select',
    cellRenderer: SecurityCheckboxCell(gridIndex)
  }
]

const toggleDetailExpanded = ({ node }: CellClickedEvent) =>
  node?.setExpanded(!node.expanded)

export const customizableColumns = (
  _gridIndex: number
): Array<AddHide<ColDef>> => [
  {
    cellRenderer: DepthOfMarketControls,
    cellClass: styles.noBg,
    colId: 'DepthTools',
    hide: false,
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMovable: false,
    width: 40
  },
  {
    colId: ISSUER,
    field: ISSUER,
    headerName: 'Ticker',
    width: 75,
    cellClass: (cellParams) => {
      const displayIssuer = shouldDisplayIssuer(cellParams)
      return cx(displayIssuer || 'hideIssuer', 'bold')
    },
    onCellClicked: toggleDetailExpanded,
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    menuTabs: ['columnsMenuTab'],
    hide: false
  },
  {
    colId: COUPON,
    headerName: 'Coupon',
    field: 'coupon',
    cellClass: cx('number'),
    onCellClicked: toggleDetailExpanded,
    width: 65,
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    menuTabs: ['columnsMenuTab'],
    hide: false,
    valueFormatter: ({ value: coupon }) =>
      coupon ? `${formatCoupon(coupon)}` : ''
  },
  {
    colId: MATURITY,
    field: 'maturityDate',
    headerName: 'Maturity',
    headerClass: cx(styles.textRight),
    cellClass: cx('number'),
    onCellClicked: toggleDetailExpanded,
    width: 70,
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    hide: false,
    menuTabs: ['columnsMenuTab'],
    valueFormatter: ({ value: maturityDate }) =>
      maturityDate ? dayjs(maturityDate).format('MM/YY') : ''
  },
  {
    colId: BEST_BID_HIT,
    headerName: 'Hit',
    headerClass: styles.hideHeaderText,
    cellClass: cx('bidOfferCellText', 'bid'),
    hide: false,
    width: 32,
    cellRenderer: HitLiftCell('buy'),
    suppressColumnsToolPanel: true,
    suppressAutoSize: true
  },
  {
    colId: BEST_BID_SIZE,
    field: BEST_BID_SIZE,
    headerName: 'Bid Sz',
    headerClass: cx(styles.textRight),
    cellClass: cx('bidOfferCellText', 'bid', styles.fullHeight),
    width: 85,
    hide: false,
    cellRenderer: BestSizeCell('buy'),
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    suppressColumnsToolPanel: true
  },
  {
    colId: BEST_BID_PRICE,
    field: BEST_BID_PRICE,
    headerName: 'Best Bid',
    headerClass: cx(styles.textRight),
    cellClass: cx('bidOfferCellText', 'bid', styles.fullHeight),
    width: 70,
    hide: false,
    cellRenderer: BestPriceCell('buy'),
    menuTabs: ['columnsMenuTab'],
    suppressColumnsToolPanel: true,
    suppressAutoSize: true
  },
  {
    colId: BEST_BID_SPREAD,
    field: BEST_BID_SPREAD,
    headerName: 'Bid Spr',
    headerClass: cx(styles.textRight),
    cellClass: cx('bidOfferCellText', 'bid', styles.fullHeight),
    width: 80,
    cellRenderer: BestSpreadCell('buy'),
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    menuTabs: ['columnsMenuTab'],
    hide: false
  },
  {
    colId: BEST_BID_YTW,
    field: BEST_BID_YTW,
    headerName: 'Bid YTW',
    headerClass: cx(styles.textRight),
    cellClass: cx('number'),
    width: 65,
    cellRenderer: BestYTWCell('buy'),
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: BEST_OFFER_YTW,
    field: BEST_OFFER_YTW,
    headerName: 'Ofr YTW',
    headerClass: cx(styles.textRight),
    cellClass: cx('number'),
    width: 65,
    cellRenderer: BestYTWCell('sell'),
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: BEST_OFFER_SPREAD,
    field: BEST_OFFER_SPREAD,
    headerName: 'Ofr Spr',
    headerClass: cx(styles.textRight),
    cellClass: cx('bidOfferCellText', 'offer', styles.fullHeight),
    width: 80,
    cellRenderer: BestSpreadCell('sell'),
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    menuTabs: ['columnsMenuTab'],
    hide: false
  },
  {
    colId: BEST_OFFER_PRICE,
    field: BEST_OFFER_PRICE,
    headerName: 'Best Ofr',
    headerClass: cx(styles.textRight),
    cellClass: cx('bidOfferCellText', 'offer', styles.fullHeight),
    width: 70,
    cellRenderer: BestPriceCell('sell'),
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    menuTabs: ['columnsMenuTab'],
    hide: false
  },
  {
    colId: BEST_OFFER_SIZE,
    field: BEST_OFFER_SIZE,
    headerClass: cx(styles.textRight),
    headerName: 'Ofr Sz',
    cellClass: cx('bidOfferCellText', 'offer', styles.fullHeight),
    width: 85,
    cellRenderer: BestSizeCell('sell'),
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    menuTabs: ['columnsMenuTab'],
    hide: false
  },
  {
    colId: BEST_OFFER_LIFT,
    headerName: 'Lift',
    headerClass: styles.hideHeaderText,
    cellClass: cx('bidOfferCellText', 'offer'),
    width: 32,
    cellRenderer: HitLiftCell('sell'),
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    hide: false
  },
  {
    colId: MARKET_VOLUME,
    field: 'marketVolume',
    cellClass: 'number',
    headerName: 'Mkt Vol',
    headerClass: cx(styles.textRight),
    width: 65,
    suppressAutoSize: true,
    hide: true,
    menuTabs: ['columnsMenuTab'],
    suppressColumnsToolPanel: true,
    cellRenderer: MarketVolumeCell
  },
  {
    colId: CURRENCY,
    field: 'currency',
    headerName: 'Currency',
    width: 75,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: CUSIP,
    field: 'cusip',
    headerName: 'CUSIP',
    width: 80,
    suppressAutoSize: true,
    hide: true,
    menuTabs: ['columnsMenuTab']
  },
  {
    colId: ISIN,
    field: 'isin',
    headerName: 'ISIN',
    width: 110,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: BENCHMARK_NAME,
    field: 'benchmarkName',
    headerName: 'Bench Name',
    width: 90,
    suppressAutoSize: true,
    tooltipField: 'benchmarkName',
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: AMOUNT_ISSUED,
    field: 'amountIssued',
    headerName: 'Amt Issued',
    headerClass: cx(styles.textRight),
    cellClass: cx('number'),
    valueFormatter: ({ value }) => (value as number)?.toLocaleString(),
    width: 70,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: AMOUNT_OUTSTANDING,
    field: 'amountOutstanding',
    headerName: 'Amt Out',
    headerClass: styles.textRight,
    cellClass: cx('number'),
    valueFormatter: ({ value }) => (value as number)?.toLocaleString(),
    width: 70,
    menuTabs: ['columnsMenuTab'],
    suppressAutoSize: true,
    hide: true
  },
  {
    colId: BENCHMARK_ISIN,
    field: 'benchmarkISIN',
    headerName: 'Bench ISIN',
    width: 100,
    menuTabs: ['columnsMenuTab'],
    suppressAutoSize: true,
    hide: true
  },
  {
    colId: BENCHMARK_SECURITY,
    field: 'benchmarkSecurity',
    headerName: 'Bench Security',
    width: 100,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: COUNTRY,
    field: 'country',
    headerName: 'Country',
    width: 53,
    menuTabs: ['columnsMenuTab'],
    suppressAutoSize: true,
    hide: true
  },
  {
    colId: ISSUE_DATE,
    field: 'issueDate',
    headerName: 'Issue Date',
    hide: true,
    width: 80,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    valueFormatter: ({ value }) =>
      value instanceof Date ? formatMaturityFullDate(value) : ''
  },
  {
    colId: MARKUP_RATE,
    field: 'markupRate',
    headerName: 'Markup',
    headerClass: cx(styles.textRight),
    cellClass: cx('number'),
    width: 50,
    suppressAutoSize: true,
    hide: true,
    menuTabs: ['columnsMenuTab'],
    valueFormatter: ({ value }) =>
      (value as number)?.toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 4
      })
  },
  {
    colId: SETTLEMENT_DATE,
    field: 'settlementDate',
    headerName: 'Settle',
    width: 75,
    menuTabs: ['columnsMenuTab'],
    suppressAutoSize: true,
    hide: true,
    valueFormatter: ({ value }) =>
      value instanceof Date ? formatMaturityFullDate(value) : ''
  },
  {
    colId: S_AND_P_RATING,
    field: 'sAndPRating',
    headerName: 'S&P Rating',
    width: 75,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: SECTOR,
    field: 'sector',
    headerName: 'Sector',
    width: 120,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: SERIES,
    field: 'series',
    headerName: 'Series',
    width: 60,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: ISSUER_NAME,
    field: 'issuerName',
    headerName: 'Issuer Name',
    width: 175,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    tooltipField: 'issuerName',
    hide: true
  }
]

const admin7ChordColumns: Array<AddHide<ColDef>> = [
  {
    colId: AVG_DAILY_7CHORD,
    headerName: 'Avg Td/D',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells
  },
  {
    colId: TIMES_TRADED_7CHORD,
    headerName: 'Tds 60d',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells
  },
  {
    colId: TIME_SINCE_7CHORD,
    headerName: 'LastTd',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells
  },
  {
    colId: EXPERT_PREDICTION_7CHORD,
    headerName: 'Mid OAS 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells
  },
  /// ///////////Bid Section //////////////////
  {
    colId: `bid${PRICE_7CHORD}`,
    headerName: 'Bid $ 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'bid',
        name: PRICE_7CHORD
      }
    }
  },
  {
    colId: `bid${DATETIME_7CHORD}`,
    headerName: 'Bid Upd 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'bid',
        name: DATETIME_7CHORD
      }
    }
  },
  {
    colId: `bid${OAS_SPREAD_7CHORD}`,
    headerName: 'Bid OAS 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'bid',
        name: OAS_SPREAD_7CHORD
      }
    }
  },
  {
    colId: `bid${T_SPREAD}`,
    headerName: 'Bid TSpd 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'bid',
        name: T_SPREAD
      }
    }
  },
  {
    colId: `bid${YTM_7CHORD}`,
    headerName: 'Bid YTM 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'bid',
        name: YTM_7CHORD
      }
    }
  },
  {
    colId: `bid${DAY_7CHORD}`,
    headerName: 'Bid %/D 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'bid',
        name: DAY_7CHORD
      }
    }
  },
  {
    colId: `bid${SIGNAL_7CHORD}`,
    headerName: 'Bid %/L 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'bid',
        name: SIGNAL_7CHORD
      }
    }
  },
  {
    colId: `bid${LAST_CLOSE_7CHORD}`,
    headerName: 'Bid Cls 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'bid',
        name: LAST_CLOSE_7CHORD
      }
    }
  },
  /// ///////////Ofr Section //////////////////
  {
    colId: `ofr${PRICE_7CHORD}`,
    headerName: 'Ofr $ 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'ofr',
        name: PRICE_7CHORD
      }
    }
  },
  {
    colId: `ofr${DATETIME_7CHORD}`,
    headerName: 'Ofr Upd 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'ofr',
        name: DATETIME_7CHORD
      }
    }
  },
  {
    colId: `ofr${OAS_SPREAD_7CHORD}`,
    headerName: 'Ofr OAS 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'ofr',
        name: OAS_SPREAD_7CHORD
      }
    }
  },
  {
    colId: `ofr${T_SPREAD}`,
    headerName: 'Ofr TSpd 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'ofr',
        name: T_SPREAD
      }
    }
  },
  {
    colId: `ofr${YTM_7CHORD}`,
    headerName: 'Ofr YTM 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'ofr',
        name: YTM_7CHORD
      }
    }
  },
  {
    colId: `ofr${DAY_7CHORD}`,
    headerName: 'Ofr %/D 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'ofr',
        name: DAY_7CHORD
      }
    }
  },
  {
    colId: `ofr${SIGNAL_7CHORD}`,
    headerName: 'Ofr %/L 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'ofr',
        name: SIGNAL_7CHORD
      }
    }
  },
  {
    colId: `ofr${LAST_CLOSE_7CHORD}`,
    headerName: 'Ofr Cls 7C',
    width: 95,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true,
    cellRenderer: ChordCells,
    cellRendererParams: {
      bidOfr: {
        type: 'ofr',
        name: LAST_CLOSE_7CHORD
      }
    }
  }
]

const makeDetermineMyOrderfieldEditable = (
  type: OrderType,
  field: OrderFields,
  reverseResult: boolean
) => {
  return ({
    context,
    data: security
  }:
    | EditableCallbackParams<Security>
    | SuppressNavigableCallbackParams<Security>) => {
    const trueResult = !reverseResult
    const falseResult = reverseResult

    // missing data cases
    if (!security) return falseResult
    const manager: OrderManager =
      type === 'buy' ? context.buyOrderManager : context.sellOrderManager
    if (!manager) return trueResult

    if (manager.canEditThisField(field, security.id)) return trueResult
    return falseResult
  }
}

const edit_field_size = 90

export const pinnedRightColumns = (
  gridIndex: number
): Array<AddHide<ColGroupDef>> => {
  return [
    {
      groupId: 'myOrders',
      suppressColumnsToolPanel: true,
      /*  Child widths should be close to --myOrdersBodyWidth */
      children: [
        {
          colId: MY_BID_SELECT,
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          lockPosition: true,
          suppressColumnsToolPanel: true,
          headerComponent: getHeaderOrderCheckbox(gridIndex, 'buy'),
          headerClass: cx(styles.padding, styles.regular),
          columnGroupShow: 'open',
          width: 32,
          cellClass: cx(styles.padding, styles.regular, styles.fullHeight),
          cellRenderer: OrderCheckboxCell('buy'),
          ...orderTooltipProps('buy')
        },
        {
          colId: MY_BID_SUBMIT_OR_CANCEL,
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          headerName: '',
          columnGroupShow: 'open',
          lockPosition: true,
          width: 25,
          suppressColumnsToolPanel: true,
          cellClass: cx(styles.fullHeight),
          cellRenderer: SubmitOrCancelCell('buy'),
          ...orderTooltipProps('buy')
        },
        {
          colId: MY_BID_SIZE,
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          field: MY_BID_SIZE,
          headerName: 'Bid Size',
          columnGroupShow: 'open',
          lockPosition: true,
          headerClass: cx(styles.padding, styles.medium, styles.textRight),
          cellClass: cx(styles.fullHeight, 'number', styles.item),
          cellRenderer: withOrderField(MyOrderRenderer, 'buy', 'size'),
          cellEditor: withOrderManager(MyOrderEditor, 'buy', 'size'),
          width: edit_field_size,
          suppressColumnsToolPanel: true,
          suppressNavigable: makeDetermineMyOrderfieldEditable(
            'buy',
            'size',
            true
          ),
          editable: makeDetermineMyOrderfieldEditable('buy', 'size', false),
          singleClickEdit: true,
          ...orderTooltipProps('buy')
        },
        {
          colId: MY_BID_PRICE,
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          field: MY_BID_PRICE,
          headerName: 'Bid Price',
          columnGroupShow: 'open',
          headerClass: cx(styles.padding, styles.medium, styles.textRight),
          cellClass: cx(styles.fullHeight, 'number', styles.item),
          cellRenderer: withOrderField(MyOrderRenderer, 'buy', 'price'),
          cellEditor: withOrderManager(MyOrderEditor, 'buy', 'price'),
          width: edit_field_size,
          lockPosition: true,
          suppressColumnsToolPanel: true,
          suppressNavigable: makeDetermineMyOrderfieldEditable(
            'buy',
            'price',
            true
          ),
          editable: makeDetermineMyOrderfieldEditable('buy', 'price', false),
          singleClickEdit: true,
          ...orderTooltipProps('buy')
        },
        {
          colId: MY_BID_SPREAD,
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          field: MY_BID_SPREAD,
          headerName: 'Bid Spread',
          columnGroupShow: 'open',
          headerClass: cx(styles.padding, styles.medium, styles.textRight),
          cellClass: cx(styles.fullHeight, 'number', styles.item),
          cellRenderer: withOrderField(MyOrderRenderer, 'buy', 'spread'),
          cellEditor: withOrderManager(MyOrderEditor, 'buy', 'spread'),
          width: edit_field_size,
          lockPosition: true,
          suppressColumnsToolPanel: true,
          suppressNavigable: makeDetermineMyOrderfieldEditable(
            'buy',
            'spread',
            true
          ),
          editable: makeDetermineMyOrderfieldEditable('buy', 'spread', false),
          singleClickEdit: true,
          ...orderTooltipProps('buy')
        },
        {
          colId: 'PLACEHOLDER3',
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          columnGroupShow: 'open',
          width: 35,
          suppressColumnsToolPanel: true,
          lockPosition: true
        },
        {
          colId: MY_OFFER_SPREAD,
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          field: MY_OFFER_SPREAD,
          headerName: 'Offer Spread',
          columnGroupShow: 'open',
          headerClass: cx(styles.padding, styles.medium, styles.textRight),
          cellClass: cx(styles.fullHeight, 'number', styles.item),
          cellRenderer: withOrderField(MyOrderRenderer, 'sell', 'spread'),
          cellEditor: withOrderManager(MyOrderEditor, 'sell', 'spread'),
          width: edit_field_size,
          suppressColumnsToolPanel: true,
          lockPosition: true,
          suppressNavigable: makeDetermineMyOrderfieldEditable(
            'sell',
            'spread',
            true
          ),
          editable: makeDetermineMyOrderfieldEditable('sell', 'spread', false),
          singleClickEdit: true,
          ...orderTooltipProps('sell')
        },
        {
          colId: MY_OFFER_PRICE,
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          field: MY_OFFER_PRICE,
          headerName: 'Offer Price',
          columnGroupShow: 'open',
          headerClass: cx(styles.padding, styles.medium, styles.textRight),
          cellClass: cx(styles.fullHeight, 'number', styles.item),
          cellRenderer: withOrderField(MyOrderRenderer, 'sell', 'price'),
          cellEditor: withOrderManager(MyOrderEditor, 'sell', 'price'),
          width: edit_field_size,
          lockPosition: true,
          suppressColumnsToolPanel: true,
          suppressNavigable: makeDetermineMyOrderfieldEditable(
            'sell',
            'price',
            true
          ),
          editable: makeDetermineMyOrderfieldEditable('sell', 'price', false),
          singleClickEdit: true,
          ...orderTooltipProps('sell')
        },
        {
          colId: MY_OFFER_SIZE,
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          field: MY_OFFER_SIZE,
          headerName: 'Offer Size',
          columnGroupShow: 'open',
          headerClass: cx(styles.padding, styles.medium, styles.textRight),
          cellClass: cx(styles.fullHeight, 'number', styles.item),
          cellRenderer: withOrderField(MyOrderRenderer, 'sell', 'size'),
          cellEditor: withOrderManager(MyOrderEditor, 'sell', 'size'),
          width: edit_field_size,
          lockPosition: true,
          suppressColumnsToolPanel: true,
          suppressNavigable: makeDetermineMyOrderfieldEditable(
            'sell',
            'size',
            true
          ),
          editable: makeDetermineMyOrderfieldEditable('sell', 'size', false),
          singleClickEdit: true,
          ...orderTooltipProps('sell')
        },
        {
          colId: MY_OFFER_SUBMIT_OR_CANCEL,
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          headerName: '',
          columnGroupShow: 'open',
          width: 25,
          cellClass: cx(styles.fullHeight),
          suppressColumnsToolPanel: true,
          lockPosition: true,
          cellRenderer: SubmitOrCancelCell('sell'),
          ...orderTooltipProps('sell')
        },
        {
          colId: MY_OFFER_SELECT,
          suppressMovable: true,
          suppressAutoSize: true,
          pinned: 'right',
          headerComponent: getHeaderOrderCheckbox(gridIndex, 'sell'),
          headerClass: cx(styles.padding, styles.regular),
          columnGroupShow: 'open',
          width: 32,
          suppressColumnsToolPanel: true,
          cellClass: cx(styles.padding, styles.regular, styles.fullHeight),
          lockPosition: true,
          cellRenderer: OrderCheckboxCell('sell'),
          ...orderTooltipProps('sell')
        }
      ]
    }
  ]
}

const virtualColumns: Array<AddHide<ColDef>> = [
  {
    colId: BEST_BID,
    field: BEST_BID,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    colId: MY_BID,
    field: MY_BID,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    colId: BEST_OFFER,
    field: BEST_OFFER,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    colId: MY_OFFER,
    field: MY_OFFER,
    hide: true,
    suppressColumnsToolPanel: true
  }
]

export const columnDefinitions = (gridIndex: number, isAdmin: boolean) => [
  ...pinnedLeftColumns(gridIndex),
  ...customizableColumns(gridIndex),
  ...(isAdmin ? admin7ChordColumns : []),
  ...pinnedRightColumns(gridIndex),
  ...virtualColumns
]

export const mirrorColumnMapping: Record<string, string> = {
  [MY_BID_SPREAD]: BEST_BID_SPREAD,
  [MY_BID_PRICE]: BEST_BID_PRICE,
  [MY_OFFER_SPREAD]: BEST_OFFER_SPREAD,
  [MY_OFFER_PRICE]: BEST_OFFER_PRICE
}
